import React, {ChangeEvent, FormEvent, useState} from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import TextRegularGrey from "../UI/Typography/TextRegularGrey";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import svgNext from "../../assets/img/arrowRight.svg";
import TextSubheading from "../UI/Typography/TextSubheading";
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule";
import FormInput from "../UI/FormInput";
import useAppSelector from "../../hooks/useSelector";
import useAppDispatch from "../../hooks/useDispatch";
import {resetContactForm, setContactEmail, setContactName, setContactPhone} from "../../store/contactSlice";
import StyledForm from "../UI/Form/Form";
import TextField from "../UI/TextField";
import Checkbox from "../UI/Checkbox/Checkbox";
import AppLink from '../UI/Link/AppLink';
import {Typography} from "@mui/material";
import {PRIVACY_POLICY_LINK} from "../../constants/common";
import CheckboxWrap from "../UI/Checkbox/CheckboxWrap";

const ModalContactUs = ({ onClose }: IModalProps) => {
  const name = useAppSelector((state) => state.contact.name);
  const email = useAppSelector((state) => state.contact.email);
  const phone = useAppSelector((state) => state.contact.phone);
  const validate = name.trim() && email.trim() && phone.trim();

  const [accept, setAccept] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactName(e.target.value));
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactEmail(e.target.value));
  }

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setContactPhone(e.target.value));
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(resetContactForm());
    onClose();
  }

  const handleAccept = (e: ChangeEvent<HTMLInputElement>) => {
    setAccept(e.target.checked);
  }

  const handlePolicy = () => {
    window.open(PRIVACY_POLICY_LINK, '_blank');
  }

  return (
    <Modal
      onClose={onClose}
    >
      <TextModalHeading
        sx={{ marginBottom: '32px' }}
      >
        Verkaufsabteilung
      </TextModalHeading>

      <TextRegularGrey>
        Kontaktieren Sie uns
      </TextRegularGrey>
      <TextSubheading
        sx={{ margin: '8px 0' }}
      >
        +49 069 7689-6584
      </TextSubheading>
      <TextSubheading>
        sales@basi.eu
      </TextSubheading>

      <HorizontalRule />

      <TextRegularGrey
        sx={{ marginBottom: '16px' }}
      >
        Oder hinterlassen Sie Ihre Kontaktdaten
      </TextRegularGrey>

      <StyledForm
        onSubmit={handleSubmit}
      >
        <FormInput
          label='Name'
        >
          <TextField
            value={name}
            onChange={handleChangeName}
            placeholder='Vorname und Nachname'
            maxLength={50}
          />
        </FormInput>

        <FormInput
          label='E-mail'
        >
          <TextField
            value={email}
            type='email'
            onChange={handleChangeEmail}
            maxLength={50}
            placeholder='E-mail'
          />
        </FormInput>

        <FormInput
          label='Telefon'
        >
          <TextField
            value={phone}
            onChange={handleChangePhone}
            maxLength={16}
            placeholder='Telefon'
          />
        </FormInput>

        <Button
          sx={{
            width: '100%',
            marginBottom: '32px',
          }}
          type='submit'
          disabled={!validate || !accept}
        >
          Anfrage senden
          <img
            src={svgNext}
            alt=''
          />
        </Button>
      </StyledForm>

      <CheckboxWrap>
        <Checkbox
          checked={accept}
          onChange={handleAccept}
        />
        <Typography>
          Ich bin mit der <AppLink onClick={handlePolicy}>
          Datenschutzerklärung
        </AppLink> einverstanden
        </Typography>
      </CheckboxWrap>
    </Modal>
  );
};

export default ModalContactUs;
