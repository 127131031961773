import React from 'react';
import TextStepName from "../../UI/Typography/TextStepName";
import TableLayout from "./components/TableLayout";
import { useTranslation } from 'react-i18next';

const Schliesmatrix = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('matrix.title')}
      </TextStepName>
      <TableLayout />
    </div>
  );
};

export default Schliesmatrix;
