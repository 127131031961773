import { K10_TUREN_VARIANTS, K6_TUREN_VARIANTS, T250_TUREN_VARIANTS } from "../constants/turen";
import {IDoor} from "../store/types";
import { v4 } from 'uuid';

const generateDoors = (count: number, oldArray: IDoor[], language: string, productId: string|null): IDoor[] => {
  const hebelvariationenDefaultValue = (productId === '1'
    ? T250_TUREN_VARIANTS
    : productId === '2'
    ? K10_TUREN_VARIANTS
    : K6_TUREN_VARIANTS)[0].value;

  const newArray = [...Array(count)].map((_, index) => ({
    id: v4(),
    name: '',
    type: 'doppelzylinder1',
    langeAusen: '31',
    langeInnen: '31',
    bugelhohe: '30',
    hebelvariationen: hebelvariationenDefaultValue,
  }));

  return oldArray.concat(newArray);
};

export default generateDoors;
