import React from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import TextRegularGrey from "../UI/Typography/TextRegularGrey";

const ModalSonderbezeichnung = ({ onClose }: IModalProps) => {
  return (
    <Modal
      onClose={onClose}
      width='720px'
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        Sonderbezeichnung
      </TextModalHeading>

      <TextRegularGrey>
        Auf Wunsch können Schließzylinder und Schlüssel neben der Schließungsnummer mit einer Sonderbezeichnung versehen werden. Diese hilft z.b. Die Schliessgruppe/Berechtigungen eines Schlüssels leichter zu identifizieren.
      </TextRegularGrey>

      <Button
        variant='second'
        sx={{
          marginTop: '32px',
        }}
        onClick={onClose}
      >
        Schließen
      </Button>
    </Modal>
  );
};

export default ModalSonderbezeichnung;
