import TextStepName from "../../../UI/Typography/TextStepName";
import DoorSelector from "./DoorSelector";
import { TurenContentLayout } from "../styles";
import DoorParameters from "./DoorParameters";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const PageLayoutMain = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      <TextStepName sx={{ marginBottom: "28px" }}>
        {t("turen.title")}
      </TextStepName>
      <TurenContentLayout>
        <DoorSelector />
        <DoorParameters />
      </TurenContentLayout>
    </Box>
  );
};

export default PageLayoutMain;
