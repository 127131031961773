import React from 'react';
import TextStepName from "../../../UI/Typography/TextStepName";
import {BenutzerContentLayout} from "../styles";
import GroupSelector from "./GroupSelector";
import GroupParameters from "./GroupParameters";
import { useTranslation } from 'react-i18next';

const PageLayoutMain = () => {
  const { t } = useTranslation();
  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('benutzer.title')}
      </TextStepName>
      <BenutzerContentLayout>
        <GroupSelector />
        <GroupParameters />
      </BenutzerContentLayout>
    </div>
  );
};

export default PageLayoutMain;
