import {IGroup} from "../store/types";
import { v4 } from 'uuid';

const generateGroups = (count: number, oldArray: IGroup[]): IGroup[] => {
  // const currentLength = oldArray.length;

  const newArray = [...Array(count)].map((_, index) => ({
    id: v4(),
    name: '',
    keysCount: 1,
    doorIds: [],
  }));

  return oldArray.concat(newArray);
};

export default generateGroups;
