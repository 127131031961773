import React from 'react';

const Plus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20L12 4" stroke="#06326E" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 12L4 12" stroke="#06326E" strokeWidth="1.5" strokeLinecap="round"/>
  </svg>
)

export default Plus;
