import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import {ModalButtonsWrap} from "./styles";
import useAppDispatch from "../../hooks/useDispatch";
import {removeDoor, setDoorVisited} from "../../store/configSlice";
import useAppSelector from "../../hooks/useSelector";
import {setSelectedDoorId} from "../../store/appSlice";
import {getPreviousData} from "../../tools/getPreviousData";
import { useTranslation } from "react-i18next";

const ModalRemoveDoor = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const selectedDoorId = useAppSelector((state) => state.app.selectedDoorId);
  const doors = useAppSelector((state) => state.config.doors);

  const dispatch = useAppDispatch();

  const onConfirm = () => {
    const { previousIndex, previousId } = getPreviousData(doors, selectedDoorId || '');
    dispatch(removeDoor(selectedDoorId));
    dispatch(setSelectedDoorId(previousId));
    dispatch(setDoorVisited({
      doorIndex: previousIndex,
      visited: true,
    }));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
    >
      <TextModalHeading>{t('modal.remove-door.title')}</TextModalHeading>
      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onClose}
          variant='second'
        >
          {t('modal.cancel')}
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          {t('modal.delete')}
        </Button>
      </ModalButtonsWrap>
    </Modal>
  );
};

export default ModalRemoveDoor;
