import {IDoor, IGroup} from "../store/types";

export const getPreviousData = (arr: IDoor[] | IGroup[], selectedId: string): {
  previousId: string;
  previousIndex: number;
} => {
  let previousId: string;
  let previousIndex = arr.findIndex((k) => k.id === selectedId) - 1;
  if (previousIndex > -1) {
    // если существует предыдущий объект, то переключиться на него
    previousId = arr[previousIndex]?.id;
  } else {
    // если не существует, то переключиться на первый, но если равно текущему индексу, то на второй
    if (previousIndex + 1 === 0) {
      previousId = arr[1]?.id;
    } else {
      previousId = arr[0]?.id;
    }

    previousIndex = 0; // сброс индекса для метода setDoorVisited
  }

  return {
    previousIndex,
    previousId,
  }
}
