import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import TextRegularGrey from "../UI/Typography/TextRegularGrey";
import { useTranslation } from "react-i18next";

const ModalGroupName = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      maxWidth='720px'
      width='100%'
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        {t('modal.group-name.title')}
      </TextModalHeading>
      <TextRegularGrey sx={{ marginBottom: '32px' }}>
        {t('modal.group-name.text')}
      </TextRegularGrey>

      <Button
        variant='second'
        sx={{
          marginTop: '32px',
        }}
        onClick={onClose}
      >
        {t('modal.close')}
      </Button>
    </Modal>
  );
};

export default ModalGroupName;
