import {IFinalConfig} from "../types/common";
import useAppSelector from "./useSelector";
import {TUREN_ZYLINDER_TYPES} from "../constants/turen";
import getProductNameById from "../tools/getProductNameById";
import { defaultProductType } from "../constants/produkt";
interface IReturnPrepareConfig {
  getConfig: () => IFinalConfig;
}

const usePrepareConfig = (): IReturnPrepareConfig => {
  const name = useAppSelector((state) => state.contact.name);
  const email = useAppSelector((state) => state.contact.email);
  const phone = useAppSelector((state) => state.contact.phone);
  const company = useAppSelector((state) => state.contact.company);
  const street = useAppSelector((state) => state.contact.street);
  const city = useAppSelector((state) => state.contact.city);
  const zip = useAppSelector((state) => state.contact.zip);
  const customerNumber = useAppSelector((state) => state.contact.customerNumber);
  const orderType = useAppSelector((state) => state.contact.orderType);
  const productId = useAppSelector((state) => state.config.productId);
  const productType = useAppSelector((state) => state.config.productType);
  const doors = useAppSelector((state) => state.config.doors);
  const groups = useAppSelector((state) => state.config.groups);
  const comment = useAppSelector((state) => state.contact.comment);
  const language = useAppSelector((state) => state.language.value);

  const getConfig = (): IFinalConfig => {
    return {
      productId: getProductNameById(productId),
      productType: productType || defaultProductType,
      doors: doors.map((item) => {
        const type = TUREN_ZYLINDER_TYPES.find((k) => k.value === item.type)?.name || item.type;
        const door = {
          ...item,
          type,
        };
        delete door.visited;
        return door;
      }),
      groups: groups.map((item) => {
        const group = {...item};
        delete group.visited;
        return group;
      }),
      name,
      email,
      phone,
      company,
      street,
      city,
      zip,
      comment,
      customerNumber,
      orderType,
      language,
    }
  }

  return {
    getConfig,
  }
}

export default usePrepareConfig;
