const validateNumberInput = (value: string | number, min: number, max: number): number | string => {
  if (value === '') {
    return '';
  }

  let val = Math.round(Number(value));
  if (val < min) val = min;
  if (val > max) val = max;
  return val;
}

export default validateNumberInput;

