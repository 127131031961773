import React, {useEffect, useState} from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import {ModalButtonsWrap} from "./styles";
import useAppSelector from "../../hooks/useSelector";
import {IGroup} from "../../store/types";
import useAppDispatch from "../../hooks/useDispatch";
import {
  pushGroup,
  setDraftGroup,
  updateConfigGroup
} from "../../store/configSlice";
import TextField from "../UI/TextField/TextField";
import FormInput from "../UI/FormInput";
import validateNumberInput from "../../tools/validateNumberInput";
import {setSelectedGroupId} from "../../store/appSlice";
import {MAX_KEYS} from "../../constants/common";
import { useTranslation } from 'react-i18next';

const ModalEditGroup = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const groupId = useAppSelector((state) => state.app.selectedGroupId);
  const groups = useAppSelector((state) => state.config.groups);
  const draft = useAppSelector((state) => state.config.draftGroup);

  const dispatch = useAppDispatch();

  const [groupInitialName, setGroupInitialName] = useState<string>('');
  const [groupData, setGroupData] = useState<IGroup>({
    id: '',
    name: '',
    keysCount: 0,
    doorIds: [],
    visited: true,
  });

  useEffect(() => {
    if (groupId) {
      const group = groups?.find((k) => k.id === groupId);

      if (group) {
        setGroupData(group);
        setGroupInitialName(group.name);
      }

      return;
    }

    if (draft) {
      setGroupData(draft);
      setGroupInitialName(draft.name);
    }
  }, []);

  const handleChange = (field: keyof IGroup, value: string | number | string[]) => {
    setGroupData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  const onConfirm = () => {
    if (draft) {
      dispatch(pushGroup(groupData));
      dispatch(setDraftGroup(null));
      dispatch(setSelectedGroupId(groupData.id));
    } else {
      const newGroupData = {...groupData};
      newGroupData.visited = true;
      dispatch(updateConfigGroup(newGroupData));
    }

    onClose();
  }

  const onCancel = () => {
    dispatch(setDraftGroup(null));
    onClose();
  }

  const onCloseModal = () => {
    if (draft && !groupId) {
      dispatch(setDraftGroup(groupData));
    }

    onClose();
  }

  return (
    <Modal
      onClose={onCloseModal}
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        { groupInitialName }
      </TextModalHeading>

      <div>
        <FormInput
          label={t('modal.edit-group.group-name')}
          sx={{
            maxWidth: '400px',
            marginBottom: '24px',
          }}
        >
          <TextField
            value={groupData.name}
            onChange={(e) => handleChange('name', e.target.value)}
            maxLength={16}
          />
        </FormInput>

        <FormInput
          label={t('modal.edit-group.keys-amount')}
          sx={{
            marginBottom: '24px',
          }}
        >
          <TextField
            value={groupData.keysCount}
            onChange={(e) => {
              handleChange(
                'keysCount',
                validateNumberInput(e.target.value, 1, MAX_KEYS),
              )
            }}
            type='number'
          />
        </FormInput>
      </div>

      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onCancel}
          variant='second'
        >
          {t('modal.cancel')}
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          {t('modal.save')}
        </Button>
      </ModalButtonsWrap>
    </Modal>
  );
};

export default ModalEditGroup;
