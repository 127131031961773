import React from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import { useTranslation } from 'react-i18next';

const ModalSelectProduct = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
    >
      <TextModalHeading>{t('modal.select-product.title')}</TextModalHeading>
      <Button
        sx={{
          width: '100%',
          marginTop: '32px',
        }}
        onClick={onClose}
      >
        OK
      </Button>
    </Modal>
  );
};

export default ModalSelectProduct;
