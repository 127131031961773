import React, {useCallback} from 'react';
import Dialog from "../../UI/Dialog/Dialog";
import {setModalName} from "../../../store/appSlice";
import useAppSelector from "../../../hooks/useSelector";
import useAppDispatch from "../../../hooks/useDispatch";
import ModalSelectProduct from "../../Modals/ModalSelectProduct";
import ModalContactUs from "../../Modals/ModalContactUs";
import ModalRemoveDoor from "../../Modals/ModalRemoveDoor";
import ModalZylindertypen from "../../Modals/ModalZylindertypen";
import ModalRemoveGroup from "../../Modals/ModalRemoveGroup";
import ModalSonderbezeichnung from "../../Modals/ModalSonderbezeichnung";
import ModalEditDoor from "../../Modals/ModalEditDoor";
import ModalEditGroup from "../../Modals/ModalEditGroup";
import ModalWrongDoorCount from "../../Modals/ModalWrongDoorCount";
import ModalWrongGroupCount from "../../Modals/ModalWrongGroupCount";
import ModalDoorName from '../../Modals/ModalDoorName';
import ModalGroupName from '../../Modals/ModalGroupName';

const ModalController = () => {
  const modalName = useAppSelector((state) => state.app.modalName);

  const dispatch = useAppDispatch();

  const handleCloseDialog = useCallback(() => {
    dispatch(setModalName(null));
  }, []);

  return (
    <>
      <Dialog
        open={modalName === 'groupName'}
        onClose={handleCloseDialog}
      >
        <ModalGroupName
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'doorName'}
        onClose={handleCloseDialog}
      >
        <ModalDoorName
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'selectProduct'}
        onClose={handleCloseDialog}
      >
        <ModalSelectProduct
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'moreDoors'}
        onClose={handleCloseDialog}
      >
        <ModalContactUs
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'removeDoor'}
        onClose={handleCloseDialog}
      >
        <ModalRemoveDoor
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'zylindertypen'}
        onClose={handleCloseDialog}
      >
        <ModalZylindertypen
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'sonderbezeichnung'}
        onClose={handleCloseDialog}
      >
        <ModalSonderbezeichnung
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'removeGroup'}
        onClose={handleCloseDialog}
      >
        <ModalRemoveGroup
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'editDoor'}
        onClose={handleCloseDialog}
      >
        <ModalEditDoor
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'editGroup'}
        onClose={handleCloseDialog}
      >
        <ModalEditGroup
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'wrongDoorCount'}
        onClose={handleCloseDialog}
      >
        <ModalWrongDoorCount
          onClose={handleCloseDialog}
        />
      </Dialog>

      <Dialog
        open={modalName === 'wrongGroupCount'}
        onClose={handleCloseDialog}
      >
        <ModalWrongGroupCount
          onClose={handleCloseDialog}
        />
      </Dialog>
    </>
  );
};

export default ModalController;
