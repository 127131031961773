import {IDoor, IGroup} from "../store/types";

export const getFirstUnvisitedIndex = (data: IDoor[] | IGroup[]): number => {
  for (let i = 0; i < data.length; i++) {
    if (!data[i].visited) {
      return i;
    }
  }

  return -1;
}
