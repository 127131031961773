import React from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import {ModalButtonsWrap} from "./styles";
import useAppDispatch from "../../hooks/useDispatch";
import {setConfigGroups} from "../../store/configSlice";
import useAppSelector from "../../hooks/useSelector";
import TextRegularGrey from '../UI/Typography/TextRegularGrey';
import {setSelectedGroupId} from "../../store/appSlice";

const ModalWrongGroupCount = ({ onClose }: IModalProps) => {
  const groups = useAppSelector((state) => state.config.groups);
  const totalGroups = useAppSelector((state) => state.config.totalGroups);

  const dispatch = useAppDispatch();

  const onConfirm = () => {
    const newArray = [...groups];
    newArray.length = Number(totalGroups);
    dispatch(setConfigGroups(newArray));
    dispatch(setSelectedGroupId(newArray[0].id));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
    >
      <TextModalHeading
        sx={{
          marginBottom: '32px',
        }}
      >
        Gruppen löschen?
      </TextModalHeading>
      <TextRegularGrey>
        Sie haben die Anzahl der Gruppen reduziert. Die {groups.length - Number(totalGroups)} Gruppen am Ende der Liste werden zusammen mit den Einstellungen gelöscht.
      </TextRegularGrey>
      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onClose}
          variant='second'
        >
          Abbrechen
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          Löschen
        </Button>
      </ModalButtonsWrap>
    </Modal>
  );
};

export default ModalWrongGroupCount;
