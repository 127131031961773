import React from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from "../UI/Modal/Modal";
import {IModalProps} from "./types";
import {ModalButtonsWrap} from "./styles";
import useAppSelector from "../../hooks/useSelector";
import useAppDispatch from "../../hooks/useDispatch";
import {removeGroup, setGroupVisited} from "../../store/configSlice";
import {getPreviousData} from "../../tools/getPreviousData";
import {setSelectedGroupId} from "../../store/appSlice";
import { useTranslation } from 'react-i18next';

const ModalRemoveGroup = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const selectedGroupId = useAppSelector((state) => state.app.selectedGroupId);
  const groups = useAppSelector((state) => state.config.groups);

  const dispatch = useAppDispatch();

  const onConfirm = () => {
    const { previousIndex, previousId } = getPreviousData(groups, selectedGroupId || '');
    dispatch(removeGroup(selectedGroupId));
    dispatch(setSelectedGroupId(previousId));
    dispatch(setGroupVisited({
      groupIndex: previousIndex,
      visited: true,
    }));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
    >
      <TextModalHeading>{t('modal.remove-group.title')}</TextModalHeading>
      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onClose}
          variant='second'
        >
          {t('modal.cancel')}
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          {t('modal.delete')}
        </Button>
      </ModalButtonsWrap>

    </Modal>
  );
};

export default ModalRemoveGroup;
