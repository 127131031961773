import {ITurenLange, LANGES_STANDARD_FILTER, TLanges} from "../constants/turen";

type TLengthMap = {
  [name: string]: {
    [cylinder: string]: {
      [insideLength: string]: string[]
    }
  }
}

const outsideLengthMap: TLengthMap = {
  '0': {
    halbzylinder: {
      10: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '81'],
    },
    doppelzylinder1: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
    },
    doppelzylinder2: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
    },
    doppelzylinder3: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
    },
    knaufzylinder: {
      K31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      K36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      K41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K71: ['31', '36', '41', '46', '51', '56', '61', '66'],
    },
  },
  '1': {
    halbzylinder: {
      10: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '81'],
    },
    doppelzylinder1: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'], 
    },
    doppelzylinder2: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
    },
    doppelzylinder3: {
      31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      71: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
    },
    knaufzylinder: {
      K31: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      K36: ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'],
      K41: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K46: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K51: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K56: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K61: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K66: ['31', '36', '41', '46', '51', '56', '61', '66', '71'],
      K71: ['31', '36', '41', '46', '51', '56', '61', '66'],
    },
  },
  '2': {
    halbzylinder: {
      10: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
    },
    doppelzylinder1: {
      30: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      35: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      40: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      45: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      50: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      55: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      60: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      65: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      70: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      75: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      80: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      85: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      90: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'], 
    },
    doppelzylinder2: {
      30: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      35: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      40: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      45: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      50: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      55: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      60: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      65: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      70: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      75: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      80: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      85: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      90: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'], 
    },
    doppelzylinder3: {
      30: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      35: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      40: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      45: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      50: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      55: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      60: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      65: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      70: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      75: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      80: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      85: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      90: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'], 
    },
    knaufzylinder: {
      K30: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K35: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K40: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K45: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K50: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K55: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K60: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K65: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K70: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K75: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K80: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K85: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
      K90: ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'],
    },
  },
};

const getLangesOptions = (langes: ITurenLange[], productId: string, cylinder: string, langeType: TLanges, insideLength?: string | undefined): ITurenLange[] => {
  let filter: string[] = LANGES_STANDARD_FILTER;

  if (
    langeType === 'ausen'
    && insideLength
    && outsideLengthMap[productId][cylinder]
    && outsideLengthMap[productId][cylinder][insideLength]
  ) {
    return langes.filter((k) => outsideLengthMap[productId][cylinder][insideLength].includes(k.value));
  } else if (
    (langeType === 'innen' || langeType === 'knaufseite')
    && !insideLength
    && outsideLengthMap[productId][cylinder])
  {
    return langes.filter((k) => Object.keys(outsideLengthMap[productId][cylinder]).includes(k.value));
  }

  if (productId === '0' || productId === '1') {
    // K6-RT
    // T250
    if (langeType === 'ausen') {
      filter = ['31', '36', '41', '46', '51', '56', '61', '66'];
    } else {
      filter = ['31', '36', '41', '46', '51', '56', '61', '66', '71', '76', '81', '86', '91'];
    }
  }

  if (productId === '2') {
    // K-10
    filter = ['30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80'];
  }

  return langes.filter((k) => filter.includes(k.value));
};

export default getLangesOptions;
