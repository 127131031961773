import useAppSelector from "./useSelector";
import useAppDispatch from "./useDispatch";
import {SyntheticEvent, useMemo} from "react";
import {
  enableTabId,
  setModalName,
  setPage,
  setSelectedDoorId, setSelectedGroupId
} from "../store/appSlice";
import generateDoors from "../tools/generateDoors";
import {setConfigDoors, setConfigGroups, setDoorVisited, setGroupVisited} from "../store/configSlice";
import generateGroups from "../tools/generateGroups";
import {getFirstUnvisitedIndex} from "../tools/getFirstUnvisitedIndex";
import downloadFile from "../tools/downloadFile";

interface IReturnPaginator {
  onChangeTab: (_: SyntheticEvent<Element, Event>, newVal: number) => void;
  onBack: () => void;
  onForward: () => void;
  onDownloadPdf: (href: string) => void;
  disablePagination: boolean;
  disableForward: boolean;
}

const usePaginator = (): IReturnPaginator => {
  const page = useAppSelector((state) => state.app.page);
  const productId = useAppSelector((state) => state.config.productId);

  // const doorsChapter = useAppSelector((state) => state.app.doorsChapter);
  const selectedDoorId = useAppSelector((state) => state.app.selectedDoorId);
  const doors = useAppSelector((state) => state.config.doors);
  const language = useAppSelector((state) => state.language.value);

  const selectedDoorIndex = useMemo(() => {
    return doors.findIndex((k) => k.id === selectedDoorId);
  }, [doors, selectedDoorId]);

  const selectedGroupId = useAppSelector((state) => state.app.selectedGroupId);
  const groups = useAppSelector((state) => state.config.groups);

  const selectedGroupIndex = useMemo(() => {
    return groups.findIndex((k) => k.id === selectedGroupId);
  }, [groups, selectedGroupId]);

  const allDoorsVisited = useMemo(() => {
    return doors.every((k) => k.visited);
  }, [doors]);

  const allGroupsVisited = useMemo(() => {
    return groups.every((k) => k.visited);
  }, [groups]);

  const disablePageMatrix = useMemo(() => {
    return page === 5 && (!allDoorsVisited || !allGroupsVisited);
  }, [allDoorsVisited, allGroupsVisited, page]);

  const dispatch = useAppDispatch();

  const nextPage = (newPage: number) => {
    dispatch(setPage(newPage));
    dispatch(enableTabId(newPage));
  }

  const validateNextPage = (newPage: number) => {
    switch (page) {
      case 1: {
        if (!productId) {
          dispatch(setModalName('selectProduct'));
          break;
        }
        nextPage(newPage);
        break;
      }

      case 2: {
        // мы на странице со счетчиком
        // если дверей меньше, чем уже есть, то открываем модальное окно с предом
        // если дверей равно, ничего не делаем, переходим на main
        // если дверей больше, чем уже есть, то добавляем разницу между числами

        if (!doors.length) {
          const newArray = generateDoors(1, doors, language, productId);
          dispatch(setConfigDoors(newArray));
          dispatch(setSelectedDoorId(newArray[0].id));
          dispatch(setDoorVisited({
            doorIndex: 0,
            visited: true,
          }));
          nextPage(newPage);
        } else if (doors.length < 0) {
          dispatch(setModalName('wrongDoorCount'));
        } else {
          nextPage(newPage);
        }

        break;
      }

      case 3: {
        // мы на главной странице
        if (allDoorsVisited) {
          if (!groups.length) {
            const newArray = generateGroups(1, groups);
            dispatch(setConfigGroups(newArray));
            dispatch(setSelectedGroupId(newArray[0].id));
            dispatch(setGroupVisited({
              groupIndex: 0,
              visited: true,
            }));
          }
          nextPage(newPage);
        } else {
          let index = selectedDoorIndex + 1;
          const next = doors[index];

          if (!next || next.visited) {
            // следующий объект не существует либо посещенный, переходим на первый непосещенный
            index = getFirstUnvisitedIndex(doors);
          }

          dispatch(setSelectedDoorId(doors[index].id));
          dispatch(setDoorVisited({
            doorIndex: index,
            visited: true,
          }));
        }
        break;
      }

      case 4: {
        // мы на главной странице
        if (allGroupsVisited) {
          // на след.страницу только если все группы visited
          nextPage(newPage);
        } else {
          let index = selectedGroupIndex + 1;
          const next = groups[index];

          if (!next || next.visited) {
            // следующий объект не существует либо посещенный, переходим на первый непосещенный
            index = getFirstUnvisitedIndex(groups);
          }

          dispatch(setSelectedGroupId(groups[index].id));
          dispatch(setGroupVisited({
            groupIndex: index,
            visited: true,
          }));
        }

        break;
      }

      default: {
        nextPage(newPage);
      }
    }
  };

  const validatePreviousPage = (newPage: number) => {
    nextPage(newPage);
  }

  const onChangeTab = (_: SyntheticEvent<Element, Event>, newVal: number) => {
    nextPage(newVal);
  };

  const onBack = () => {
    validatePreviousPage(page - 1);
  };

  const onForward = () => {
    validateNextPage(page + 1);
  };

  const onDownloadPdf = (href: string): void => {
    downloadFile(href);
  };

  return {
    onChangeTab,
    onBack,
    onForward,
    onDownloadPdf,
    disablePagination: (page === 3 && !doors.length) || (page === 4 && !groups.length),
    disableForward: disablePageMatrix,
  }
};

export default usePaginator;
