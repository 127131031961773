import {useEffect, useMemo} from "react";
import getLangesOptions from "../tools/getLangesOptions";
import {ITurenLange, TUREN_LANGES} from "../constants/turen";
import useAppSelector from "./useSelector";
import {IDoor} from "../store/types";

interface LangeOptionsReturn {
  LangesAusenOptions: ITurenLange[];
  LangesInnenOptions: ITurenLange[];
  isKnaufseite: boolean;
}

const useLangeOptions = (
  door: IDoor | undefined,
  onAusenChange: (value: string) => void,
  onInnenChange: (value: string) => void,
): LangeOptionsReturn => {
  const productId = useAppSelector((state) => state.config.productId);

  const isKnaufseite = useMemo(() => {
    return door?.type === 'knaufzylinder';
  }, [productId, door]);

  const LangesAusenOptions = useMemo(() => {
    return getLangesOptions(TUREN_LANGES, productId || '0', door?.type || 'halbzylinder', 'ausen', door?.langeInnen);
  }, [productId, door, isKnaufseite, door?.langeInnen]);

  const LangesInnenOptions = useMemo(() => {
    return getLangesOptions(TUREN_LANGES, productId || '0', door?.type || 'halbzylinder', isKnaufseite ? 'knaufseite' : 'innen');
  }, [productId, door]);

  useEffect(() => {
    if (LangesAusenOptions.every((k) => k.value !== door?.langeAusen)) {
      onAusenChange(LangesAusenOptions[0].value);
    }
  }, [LangesAusenOptions, door, onAusenChange]);

  useEffect(() => {
    if (LangesInnenOptions.every((k) => k.value !== door?.langeInnen)) {
      onInnenChange(LangesInnenOptions[0].value);
    }
  }, [LangesInnenOptions, door, onInnenChange]);

  return {
    LangesAusenOptions,
    LangesInnenOptions,
    isKnaufseite,
  }
}

export default useLangeOptions;
