import png1 from '../assets/img/produkt/k6rt.jpg';
import png1_2 from '../assets/img/produkt/k6sp.jpg';
import png2 from '../assets/img/produkt/t250.jpg';
import png2_2 from '../assets/img/produkt/t250sp.jpg';
import png3 from '../assets/img/produkt/k10.jpg';
import png3_2 from '../assets/img/produkt/kt10sp.jpg';
import {ISwitchButtonsOptions} from "../components/UI/SwitchButtons/SwitchButtons";
export interface IProduktCard {
  id: string;
  title: string;
  img: string[];
  description?: string;
  link: string;
}

export const PRODUKT_CARDS: IProduktCard[] = [
  {
    id: '0',
    title: 'K6-RT',
    img: [png1, png1_2],
    link: process.env.PUBLIC_URL + '/pdf/Info-Seite_KR-6T.pdf',
  },
  {
    id: '1',
    title: 'T250',
    img: [png2, png2_2],
    link: process.env.PUBLIC_URL + '/pdf/Info-Seite_T250.pdf',
  },
  {
    id: '2',
    title: 'K-10',
    img: [png3, png3_2],
    link: process.env.PUBLIC_URL + '/pdf/Info-Seite_K10.pdf',
  },
];

export const defaultProductType = 'werksprofil';

export const PRODUCT_TYPES: ISwitchButtonsOptions[] = [
  {
    name: 'Werksprofil',
    value: 'werksprofil',
  },
  {
    name: 'Sonderprofil',
    value: 'sonderprofil',
  },
];
