import png1 from "../assets/img/keys/fa-kopf2.jpg";
import png2 from "../assets/img/keys/ov-kopf2.jpg";
import png3 from "../assets/img/keys/original.jpg";

export interface IKeyProfiles {
  value: string;
  name: string;
}

export const FA_KEYS_TYPES: IKeyProfiles[] = [
  {
    value: "abu-41dfa",
    name: "ABU-41D",
  },

  {
    value: "abu-41fa",
    name: "ABU-41",
  },
  {
    value: "abu-86dfa",
    name: "ABU-86D",
  },
  {
    value: "abu-91fa",
    name: "ABU-91",
  },
  {
    value: "agb-1fa",
    name: "AGB-1",
  },
  {
    value: "bab-3dfa",
    name: "BAB-3D",
  },
  {
    value: "basi-asfa",
    name: "BASI-AS",
  },
  {
    value: "basi-bmfa",
    name: "BASI-BM",
  },
  {
    value: "basi-vbfa",
    name: "BASI-VB",
  },
  {
    value: "basi-cofa",
    name: "BASI-CO",
  },
  {
    value: "basi-vfa",
    name: "BASI-V",
  },
  {
    value: "bk-6dfa",
    name: "BK-6D",
  },
  {
    value: "ce-53dfa",
    name: "CE-53D",
  },
  {
    value: "ce-7dfa",
    name: "CE-7D",
  },
  {
    value: "ce-8dfa",
    name: "CE-8D",
  },
  {
    value: "ci-48fa",
    name: "CI-48",
  },
  {
    value: "cor-1dfa",
    name: "COR-1D",
  },
  {
    value: "dom-20dfa",
    name: "DOM-20D",
  },
  {
    value: "dom-21dfa",
    name: "DOM-21D",
  },
  {
    value: "dom-75fa",
    name: "DOM-75",
  },
  {
    value: "ev-16fa",
    name: "EV-16",
  },
  {
    value: "ev-6dfa",
    name: "EV-6D",
  },
  {
    value: "ev-9dfa",
    name: "EV-9D",
  },
  {
    value: "ff-1dfa",
    name: "FF-1D",
  },
  {
    value: "flafa",
    name: "FLA",
  },
  {
    value: "ge-5dfa",
    name: "GE-5D",
  },
  {
    value: "gea-5dfa",
    name: "GEA-5D",
  },
  {
    value: "is-15fa",
    name: "IS-15",
  },
  {
    value: "is-7dfa",
    name: "IS-7D",
  },
  {
    value: "kfv-1fa",
    name: "KFV-1",
  },
  {
    value: "lip-1dfa",
    name: "LIP-1D",
  },
  {
    value: "lip-4fa",
    name: "LIP-4",
  },
  {
    value: "lit-1ifa",
    name: "LIT-1I",
  },
  {
    value: "lit-2dfa",
    name: "LIT-2D",
  },
  {
    value: "st-1ifa",
    name: "ST-1I",
  },
  {
    value: "te-8ifa",
    name: "TE-8I",
  },
  {
    value: "tok-6dfa",
    name: "TOK-6D",
  },
  {
    value: "top-1fa",
    name: "TOP-1",
  },
  {
    value: "u-15dfa",
    name: "U-15D",
  },
  {
    value: "u-15fa",
    name: "U-15",
  },
  {
    value: "u-4dfa",
    name: "U-4D",
  },
  {
    value: "u-6dfa",
    name: "U-6D",
  },
  {
    value: "va-8dfa",
    name: "VA-8D",
  },
  {
    value: "wil-4dfa",
    name: "WIL-4D",
  },
  {
    value: "win-5dfa",
    name: "WIN-5D",
  },
  {
    value: "win-5fa",
    name: "WIN-5",
  },
  {
    value: "ya-1dfa",
    name: "YA-1D",
  },
  {
    value: "ya-22dfa",
    name: "YA-22D",
  },
  {
    value: "ze-6dfa",
    name: "ZE-6D",
  },
  {
    value: "ze-6fa",
    name: "ZE-6",
  },
  {
    value: "ze-9dfa",
    name: "ZE-9D",
  },
];

export const OV_KEYS_TYPES: IKeyProfiles[] = [
  {
    value: "abu-41dov",
    name: "ABU-41D",
  },
  {
    value: "abu-41ov",
    name: "ABU-41",
  },
  {
    value: "abu-86dov",
    name: "ABU-86D",
  },
  {
    value: "bk-6dov",
    name: "BK-6D",
  },
  {
    value: "ce-7dov",
    name: "CE-7D",
  },
  {
    value: "cor-1dov",
    name: "COR-1D",
  },
  {
    value: "dom-20dov",
    name: "DOM-20D",
  },
  {
    value: "dom-21dov",
    name: "DOM-21D",
  },
  {
    value: "ev-6dov",
    name: "EV-6D",
  },
  {
    value: "ev-9dov",
    name: "EV-9D",
  },
  {
    value: "ge-5dov",
    name: "GE-5D",
  },
  {
    value: "kfv-1ov",
    name: "KFV-1",
  },
  {
    value: "st-1iov",
    name: "ST-1I",
  },
  {
    value: "te-8iov",
    name: "TE-8I",
  },
  {
    value: "tok-6dov",
    name: "TOK-6D",
  },
  {
    value: "top-1ov",
    name: "TOP-1",
  },
  {
    value: "u-15dov",
    name: "U-15D",
  },
  {
    value: "u-15ov",
    name: "U-15",
  },
  {
    value: "va-8dov",
    name: "VA-8D",
  },
  {
    value: "wil-4dov",
    name: "WIL-4D",
  },
  {
    value: "win-5dov",
    name: "WIN-5D",
  },
  {
    value: "win-5ov",
    name: "WIN-5",
  },
  {
    value: "ze-6dov",
    name: "ZE-6D",
  },
  {
    value: "ze-6ov",
    name: "ZE-6",
  },
  {
    value: "ze-9dov",
    name: "ZE-9D",
  },
];

export const ORIGINAL_KEYS_TYPES: IKeyProfiles[] = [
  {
    value: "abu-17d",
    name: "ABU-17D",
  },
  {
    value: "abu-86d",
    name: "ABU-86D",
  },
  {
    value: "bk-7d",
    name: "BK-7D",
  },
  {
    value: "ce-53d",
    name: "CE-53D",
  },
  {
    value: "wil-66d",
    name: "WIL-66D",
  },
  {
    value: "win-22",
    name: "WIN-22",
  },
];

export enum IKeysBlankNames {
  FA = "fa-head",
  OV = "ov-head",
  ORIGINAL = "original-head",
}

export interface IKeysProduktCard {
  id: string;
  title: IKeysBlankNames;
  img: string[];
  keysTypes: IKeyProfiles[];
}

export const KEYS_PRODUKT_CARDS: IKeysProduktCard[] = [
  {
    id: "0",
    title: IKeysBlankNames.FA,
    img: [png1],
    keysTypes: FA_KEYS_TYPES,
  },
  {
    id: "1",
    title: IKeysBlankNames.OV,
    img: [png2],
    keysTypes: OV_KEYS_TYPES,
  },
  {
    id: "2",
    title: IKeysBlankNames.ORIGINAL,
    img: [png3],
    keysTypes: ORIGINAL_KEYS_TYPES,
  },
];
