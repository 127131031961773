import { saveAs } from 'file-saver';

const downloadFile = (filename: string, data?: File) => {
  if (data) {
    saveAs(new Blob([data], { type: 'application/pdf' }), filename);
    return
  }
  const element = document.createElement("a");
  element.setAttribute("href", filename);
  element.setAttribute("download", filename);
  element.style.display = "none";

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default downloadFile;
