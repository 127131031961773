import {ChangeEvent} from 'react';
import TextStepName from "../../UI/Typography/TextStepName";
import {ProjectNameBox, TurenCounterBox} from "./styles";
import TextField from "../../UI/TextField/TextField";
import FormInput from "../../UI/FormInput";
import useAppDispatch from "../../../hooks/useDispatch";
import {setProjectName} from "../../../store/configSlice";
import useAppSelector from "../../../hooks/useSelector";
import { useTranslation } from 'react-i18next';

const Project = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const name = useAppSelector((state) => state.config.projectName);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectName(e.target.value));
  }

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('project.title')}
      </TextStepName>
      <TurenCounterBox>
        <ProjectNameBox>
          <div>
            <FormInput
              label={t('project.input')}
            >
              <TextField
                value={name}
                onChange={handleChangeName}
                maxLength={50}
                placeholder={t('project.input')}
              />
            </FormInput>
          </div>
        </ProjectNameBox>
      </TurenCounterBox>
    </div>
  );
};

export default Project;
