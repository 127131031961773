import React, {useCallback, useEffect, useState, useMemo} from 'react';
import TextModalHeading from "../UI/Typography/TextModalHeading";
import Button from "../UI/Button/Button";
import Modal from '../UI/Modal/Modal';
import {IModalProps} from "./types";
import {ModalButtonsWrap} from "./styles";
import useAppSelector from "../../hooks/useSelector";
import {IDoor} from "../../store/types";
import useAppDispatch from "../../hooks/useDispatch";
import {pushDoor, updateConfigDoor, setDraftDoor} from "../../store/configSlice";
import TextField from "../UI/TextField/TextField";
import FormInput from "../UI/FormInput";
import Select from "../UI/Select/Select";
import {BUGELHOHE_TUREN_LANGES, K10_TUREN_VARIANTS, K6_TUREN_VARIANTS, T250_TUREN_VARIANTS, TUREN_ZYLINDER_TYPES} from "../../constants/turen";
import {setSelectedDoorId} from "../../store/appSlice";
import useLangeOptions from "../../hooks/useLangeOptions";
import { useTranslation } from 'react-i18next';

const ModalEditDoor = ({ onClose }: IModalProps) => {
  const { t } = useTranslation();
  const selectedDoorId = useAppSelector((state) => state.app.selectedDoorId);
  const doors = useAppSelector((state) => state.config.doors);
  const draft = useAppSelector((state) => state.config.draftDoor);
  const productId = useAppSelector((state) => state.config.productId);

  const dispatch = useAppDispatch();

  const [doorInitialName, setDoorInitialName] = useState<string>('');
  const [doorData, setDoorData] = useState<IDoor>({
    id: '',
    name: '',
    type: '',
    langeAusen: '',
    langeInnen: '',
    visited: true,
  });
  const showLange = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === doorData?.type)?.showLange;
  }, [doorData]);
  const showBugelhohe = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === doorData?.type)?.showBugelhohe;
  }, [doorData]);
  const showHebelvariationen = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === doorData?.type)?.showHebelvariationen;
  }, [doorData]);
  const hebelvariationenVariations = useMemo(() => {
    if (productId === '1') {
      return T250_TUREN_VARIANTS;
    }
    if (productId === '2') {
      return K10_TUREN_VARIANTS;
    }
    return K6_TUREN_VARIANTS;
  }, [productId]);

  const handleChange = useCallback((field: keyof IDoor, value: string) => {
    setDoorData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const handleChangeLangeAusen = useCallback((value: string) => {
    handleChange('langeAusen', value);
  }, [handleChange]);

  const handleChangeLangeInnen = useCallback((value: string) => {
    handleChange('langeInnen', value);
  }, [handleChange]);

  const handleChangeBugelhohe = useCallback((value: string) => {
    handleChange('bugelhohe', value);
  }, [handleChange]);

  const handleChangeHebelvariationen = useCallback((value: string) => {
    handleChange('hebelvariationen', value);
  }, [handleChange]);

  const {
    LangesInnenOptions,
    LangesAusenOptions,
    isKnaufseite,
  } = useLangeOptions(doorData, handleChangeLangeAusen, handleChangeLangeInnen);

  useEffect(() => {
    if (selectedDoorId) {
      const door = doors?.find((k) => k.id === selectedDoorId);

      if (door) {
        setDoorData(door);
        setDoorInitialName(door.name);
      }

      return;
    }

    if (draft) {
      setDoorData(draft);
      setDoorInitialName(draft.name);
    }
  }, []);

  const onConfirm = () => {
    if (draft) {
      dispatch(pushDoor(doorData));
      dispatch(setDraftDoor(null));
      dispatch(setSelectedDoorId(doorData.id));
    } else {
      const newDoorData = {...doorData};
      newDoorData.visited = true;
      dispatch(updateConfigDoor(newDoorData));
    }

    onClose();
  }

  const onCancel = () => {
    dispatch(setDraftDoor(null));
    onClose();
  }

  const onCloseModal = () => {
    if (draft && !selectedDoorId) {
      dispatch(setDraftDoor(doorData));
    }

    onClose();
  }

  return (
    <Modal
      onClose={onCloseModal}
    >
      <TextModalHeading sx={{ marginBottom: '32px' }}>
        { doorInitialName }
      </TextModalHeading>

      <FormInput
        label={t('modal.edit-door.door-name')}
        sx={{
          maxWidth: '400px',
          marginBottom: '24px',
        }}
      >
        <TextField
          value={doorData.name}
          onChange={(e) => handleChange('name', e.target.value)}
          maxLength={16}
        />
      </FormInput>

      <FormInput
        label={t('modal.edit-door.cylinder-type')}
        sx={{
          maxWidth: '400px',
          marginBottom: '24px',
        }}
      >
        <Select
          options={TUREN_ZYLINDER_TYPES}
          translate={true}
          value={doorData.type}
          onChange={(e) => handleChange('type', e.target.value as string)}
        />
      </FormInput>
      
      {
        showLange &&
        <>
          <FormInput
            label={t('modal.edit-door.outside-length')}
            sx={{
              maxWidth: '400px',
              marginBottom: '24px',
            }}
          >
            <Select
              options={LangesAusenOptions}
              value={doorData.langeAusen}
              onChange={(e) => handleChangeLangeAusen(e.target.value as string)}
            />
          </FormInput>

          <FormInput
            label={isKnaufseite ? `${t('turen.inside-length')} (${t('turen.knob-side')})` : t('turen.inside-length')}
            sx={{
              maxWidth: '400px',
              marginBottom: '58px',
            }}
          >
            <Select
              options={LangesInnenOptions}
              value={doorData.langeInnen}
              onChange={(e) => handleChangeLangeInnen(e.target.value as string)}
            />
          </FormInput>
        </>
      }
      {
        showBugelhohe &&
        <>
          <FormInput
            label={t('turen.ironing-height')}
            sx={{
              maxWidth: '438px',
              marginBottom: '24px',
            }}
          >
            <Select
              options={BUGELHOHE_TUREN_LANGES}
              value={doorData.bugelhohe}
              onChange={(e) => handleChangeBugelhohe(e.target.value as string)}
            />
          </FormInput>
        </>
      }
      {
        showHebelvariationen &&
        <>
          <FormInput
            label={t('turen.leverage-variations')}
            sx={{
              maxWidth: '438px',
              marginBottom: '24px',
            }}
          >
            <Select
              options={hebelvariationenVariations}
              value={doorData.hebelvariationen}
              translate={true}
              onChange={(e) => handleChangeHebelvariationen(e.target.value as string)}
            />
          </FormInput>
        </>
      }

      <ModalButtonsWrap>
        <Button
          sx={{
            width: '122px',
          }}
          onClick={onCancel}
          variant='second'
        >
          {t('modal.cancel')}
        </Button>
        <Button
          sx={{
            width: '100%',
          }}
          onClick={onConfirm}
        >
          {t('modal.save')}
        </Button>
      </ModalButtonsWrap>
    </Modal>
  );
};

export default ModalEditDoor;
